import React from "react";
import { Routes, Route } from "react-router-dom";
import PageTitleChanger from "./pageTitleChanger";

import PrivateRoutes from "../Utils/PrivateRoutes";

const DashBordHomePage = React.lazy(() => import("../Pages/Home/home"));
const AccountSettingPage = React.lazy(() => import("../Pages/accountSetting"));
const Payments = React.lazy(() => import("../Pages/Payments"));
const Appointment = React.lazy(() => import("../Pages/appointment"));
const HelpAndSupport = React.lazy(() => import("../Pages/helpAndSupport"));
const ACPForm = React.lazy(() => import("../Pages/ACPForm"));
const PauseService = React.lazy(
  () => import("../Pages/pauseService/PauseService")
);
const LoginPageWithLatestDeisgn = React.lazy(
  () => import("../Pages/LogReg/loginPage")
);
const ForgetUserName = React.lazy(
  () => import("../Pages/LogReg/forgetUserName")
);
const ForgotUserNameReminderPage = React.lazy(
  () => import("../Pages/LogReg/ForgotUserNameReminder")
);
const LoginPasswordPage = React.lazy(
  () => import("../Pages/LogReg/ForgotPasswordPhoneNumber")
);
const ForgotPassword = React.lazy(
  () => import("../Pages/LogReg/ForgotPassword")
);
const ForgotPasswordSendMesgDetails = React.lazy(
  () => import("../Pages/LogReg/ForgotPasswordSendLink")
);
const ForgotPasswordVerificationCodePage = React.lazy(
  () => import("../Pages/LogReg/ForgotPasswordVerificationCode")
);
const ResetPasswordNewPage = React.lazy(
  () => import("../Pages/LogReg/ResetPasswordNew")
);
const ResetPasswordSuccessPage = React.lazy(
  () => import("../Pages/LogReg/ResetPasswordReminder")
);
const SignUpEmailPage = React.lazy(() => import("../Pages/LogReg/SignUpEmail"));
const SignUpPasswordPage = React.lazy(
  () => import("../Pages/LogReg/SignUpPassword")
);
const SignUpCompleted = React.lazy(() => import("../Pages/LogReg/SignUpAlert"));

const MultipleAccountsRegisterationPage = React.lazy(
  () => import("../Pages/LogReg/MultipleAccountsRegisteration")
);

const RescheduleAppointmentForOthersPlatform = React.lazy(
  () => import("../Pages/rescheduleAppointmentForOthers")
);
const LoginServiceAddressPage = React.lazy(
  () => import("../Pages/LogReg/loginServiceAddress")
);

const LoginServiceEmailPage = React.lazy(
  () => import("../Pages/LogReg/loginServiceEmail")
);
const LoginServiceAddressNoCaptchaPage = React.lazy(
  () => import("../Pages/LogReg/loginServiceAddressNoCaptcha")
);
const LoginServiceAddressError = React.lazy(
  () => import("../Pages/LogReg/loginServiceAddressError")
);
const ShopExperience = React.lazy(
  () => import("../Pages/accountSetting/shop-experience")
);
const ChangePlanForAgent = React.lazy(
  () => import("../Pages/changePlanForAgent/index")
);

const AllRoutes = () => {
  return (
    <>
    <PageTitleChanger/>
    <Routes>
      <Route element={<PrivateRoutes />}>
        {/* private or protected routes be here */}
      <Route element={<ACPForm />} path="/acpform" />
        <Route
          element={<AccountSettingPage />}
          path="/account-setting/:activeTab"
        />
        <Route element={<DashBordHomePage />} path="/home/:step" />
        <Route element={<DashBordHomePage hideStapper={true} />} path="/home" />
        <Route element={<Payments />} path="/payment/:paymentOption" />
        <Route element={<Appointment />} path="/appointment" />
        <Route element={<PauseService />} path="/pause-service" />
        <Route
          element={<Appointment />}
          path="/appointment/current/:orderNumber"
        />
        <Route element={<HelpAndSupport />} path="/help-and-support" />
        <Route element={<ShopExperience />} path="/update-plan" />
      </Route>
      {/* public or un-protected routes be here */}
      <Route element={<LoginPageWithLatestDeisgn />} path="/login" />
      <Route element={<ForgetUserName />} path="/new-forget-username" />
      <Route
        element={<ForgotUserNameReminderPage />}
        path="/new-forget-username-sent-reminder"
      />
      <Route element={<LoginPasswordPage />} path="/login-password" />
      <Route element={<ForgotPassword />} path="/new-forget-password" />
      <Route
        element={<ForgotPasswordSendMesgDetails fromRegister={false} />}
        path="/new-forget-password-send-msg-detail"
      />
      <Route
        element={<ForgotPasswordVerificationCodePage fromRegister={false} />}
        path="/new-forget-password-otp"
      />
      <Route
        element={<ForgotPasswordVerificationCodePage fromRegister={true} />}
        path="/signUp-password-otp"
      />
      <Route element={<ResetPasswordNewPage />} path="/new-reset-password" />
      <Route
        element={<ResetPasswordSuccessPage />}
        path="/new-reset-password-confirm"
      />
      <Route element={<SignUpEmailPage />} path="/signUp" />
      <Route
        element={<ForgotPasswordSendMesgDetails fromRegister={true} />}
        path="/signUp-send-msg-detail"
      />
      <Route
        element={<SignUpPasswordPage fromSetPass={false} />}
        path="/signUp-password"
      />
      <Route
        element={<SignUpCompleted fromSetPass={false} />}
        path="/signUp-confirm"
      />

      <Route
        element={<SignUpPasswordPage fromSetPass={true} />}
        path="/set-password"
      />
      <Route
        element={<SignUpCompleted fromSetPass={true} />}
        path="/reset-password-confirm"
      />

      <Route
        element={<RescheduleAppointmentForOthersPlatform />}
        path="/reschedule"
      />
      <Route element={<PauseService />} path="/pause-service-external" />

      <Route element={<LoginServiceAddressPage />} path="/uni-login" />
      <Route element={<LoginServiceEmailPage />} path="/uni-login-email" />

      <Route
        element={<LoginServiceAddressNoCaptchaPage />}
        path="/uni-login-nocaptcha"
      />

      <Route
        element={<LoginServiceAddressError />}
        path="/uni-login-no-match"
      />
      <Route
        element={
          <ForgotPasswordSendMesgDetails
            fromRegister={true}
            fromValidateLogin={true}
          />
        }
        path="/validate-login"
      />
      <Route
        element={
          <ForgotPasswordVerificationCodePage
            fromRegister={true}
            fromValidateLogin={true}
          />
        }
        path="/validate-login-otp"
      />
      <Route
        element={
          <ForgotPasswordSendMesgDetails
            fromRegister={true}
            fromValidateLogin={true}
            fromFirstTimeSetPass={true}
          />
        }
        path="/set-password-validate"
      />

      <Route
        element={
          <ForgotPasswordVerificationCodePage
            fromRegister={true}
            fromValidateLogin={true}
            fromFirstTimeSetPass={true}
          />
        }
        path="/set-password-validate-otp"
      />

      <Route element={<ChangePlanForAgent />} path="/change-plan" />
      <Route element={<ShopExperience />} path="/shop-exp-agent" />

      <Route element={<LoginPageWithLatestDeisgn />} path="*" />
    </Routes>
    </>
  );
};

export default AllRoutes;
