export default {
  paymentHistoryUrl: "digital/v2/payment/credit/v1/payment-history",
  paymentHistoryUrl_QA_URL:
    "digital/v2/payment/order/credit/v1/payment-history",
  //listPaymentMethodsUrl: "digital/v2/payment/credit/list-payment-methods",
  listPaymentMethods_QA_Url:
    "digital/v2/payment/order/credit/list-payment-methods",

  // addUpdatePaymentMethodUrl: "digital/v2/payment/order/payment-methods",
  //addUpdatePaymentMethodUrl: "digital/v2/payment/credit/payment-methods",
  addUpdatePaymentMethodUrl_QA:
    "digital/v2/payment/order/credit/payment-methods",

  //updatePaymentMethodUrl: "digital/v2/payment/order/updatepayment-methods",
  updatePaymentMethodUrl_QA:
    "digital/v2/payment/order/credit/updatepayment-methods",
  //deletePaymentMethodUrl: "digital/v2/payment/credit/payment-methods",
  deletePaymentMethodUrl_QA: "digital/v2/payment/order/credit/payment-methods",
  serviceDetailsUrl: "/digital/v2/accountmgmt",
  accountManagementUrl: "/digital/v2/accountmgmt",
  fetchPastAppointments: `digital/v2/techappointment/user-appointments`,
  upComingAppointmentUrl: `digital/v2/techappointment/user-appointments`,
  upComingAppointmentUrl_QA1: `digital-appointments/api/appointmentsms/createappointment/user-appointments`,
  upComingAppointmentUrl_QA: `digital/v2/techappointment/user-appointments`,
  appointmentDetailsUrl: "digital/v2/techappointment/getAppointmentDetails",
  rescheduleAppointmentUrl: "digital/v2/techappointment/rescheduleAppointment",
  submitRescheduledAppointmentUrl:
    "digital/v2/techappointment/submit-rescheduled-appointment",
  cancelAppointmentUrl:
    "digitalappointments/api/appointmentsms/createappointment/cancelAppointment",
  makePaymentMethodDefaultUrl:
    "digital/v2/payment/order/credit/make-paymentid-default",
  paymentTokenUrl: "digital/v2/payment/order/generateToken",
  paymentGenerateSignatureUrl: "digital/v2/payment/order/generateSignature",
  upcomingPaymentUrl: "digital/v2/payment/credit/upcoming-payments",
  upcomingPayment_QA_Url: "digital/v2/payment/order/credit/upcoming-payments",
  planDetailsUrl: "digital/v2/payment/credit/subscription-plan-details",
  planDetails_QA_Url:
    "digital/v2/payment/order/credit/subscription-plan-details",
  accountIdUrl: "digital/v2/payment/credit/upcoming-payments",
  accountId_QA_Url: "digital/v2/payment/order/credit/upcoming-payments",
  verifyUserNameUrl: "accountmanagement/105247428/verify-user-name",
  verifyPasswordUrl: "accountmanagement/105250492/verify-password",

  viewInvoicePDF_Url: "digital/v2/payment/credit/view-invoice-pdf",
  viewInvoicePDF_Url_QA: "digital/v2/payment/order/credit/view-invoice-pdf",

  passwordResetConfirmation: "digital/v2/accountmgmt/send-reset-confirmation",
  iAmTokenUrl: "digital/v2/accountmgmt/get-iamt",
  updateAccount: "digital/v2/accountmgmt/update-customer-profile",
  forgotPassGenerateOTPUrl: "digital/v2/accountmgmt/forgot-password",
  forgotUsernameUrl: "digital/v2/accountmgmt/forgotusername",
  validateOTP_Url: "digital/v2/accountmgmt/validate-otp",
  digitalservices_dev_serviceDetailsUrl:
    "digital/v2/accountmgmt/service-details-user",
  userInfoByAccIdUrl: "digital/v2/accountmgmt/getAccountInformation",
  registerUserAIM_url: "digital/v2/accountmgmt/register-user",
  invoiceDetailsUrl: "digital/v2/payment/credit/invoice-pdf-upcoming-payment",
  invoiceDetails_QA_Url:
    "digital/v2/payment/order/credit/invoice-pdf-upcoming-payment",
  msgToTech: "digital/v2/payment/message",
  dispatchMsgToTech: "digital/v2/techappointment/message-tech",
  resendOTPUrl: "digital/v2/accountmgmt/resend-otp",
  brightspeedGetTokenAPI: "digital/v2/digitalId",
  decryptionQstr_Url: "digital/v2/accountmgmt/unency",
  encryptionQstr_Url: "digital/v2/accountmgmt/ency",
  addressQualificationURL: "digital/v2/addressqualification",
  markPaymentDefault: "digital/v2/payment/order/credit/make-paymentid-default",
  unifiedLoginWithMobileNumberTN_lookup_Url:
    "digital/v2/addressqualification/tn-lookup",
  unifiedLoginWithEmailAddress_lookup_Url:
    "digital/v2/accountmgmt/lookup-accounts",
  case: "digital/v2/accountmgmt/create-acp-case",
  buryCableMsgUrl: "digital/v2/accountmgmt/bswinfo",
  captchaTokenValidateURL: "digital/v2/porting/recaptcha",
  repairAppointmentURL: "digital/v2/techappointment/repairAppointment",
  pauseService: "digital/v2/payment/order/suspend-order",
  dwLoggingUrl: "digital/v2/porting/dw-publish",
  purlPaymentUrl: "digital/v2/payment/order/paynow",
  paypalUrl: "digital/v2/payment/order/credit",
  amsTokenUrl: "oauth/client_credential",
  amsLookUpURL: "ams2-digital/restservices/adq-api/v1",
  getNotificationWithBan: "digital/v2/accountmgmt/ban-notification",
  notificationStatusUrl: "digital/v2/accountmgmt/notification",
  getAzureInfoUrl: "digital/v2/accountmgmt/getazinfo",
  getServiceDetails_with_ban: "digital/v2/accountmgmt/get-service-details",
  nutritionlabel: "bass/v2/product/nutritionLabel",
};
