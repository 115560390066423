import { SAVE_PAYMENT_DATA,ACTIVE_MENU } from "../utils/Util"

const initialState = {
  paymentData:{},
  activeMenuIndex:0
}

const DashboardReducer =  (state = initialState, { type, payload }) => {
  switch (type) {

  case SAVE_PAYMENT_DATA:

      return { ...state, 
        paymentData:payload
    }
    case ACTIVE_MENU:

      return { ...state, 
        activeMenuIndex:payload
    }
    
  default:
    return state
  }
}


export default DashboardReducer