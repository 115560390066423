import { useState, useEffect } from "react";
import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";

const useFetchCurrentPlan = (params = {}) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    getResponse();
  }, []);
  const getResponse = async () => {
    const env = getEnvironment();
    ApiService.get({ ...params, apiUrl: env.liveUrl }, {}, {}).then(
      (result) => {
        setData(result.data);
      }
    );
  };
  return [data];
};

// return promise
export const fetchCurrentPlan = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};

export default useFetchCurrentPlan;
