import { ENVIRONMENT } from "./EnvConfig";

export const API_URL = "https://stoplight.io/mocks/brightspeed-apiverse/";
export const SMARTY_STREET_ADDRESS_URL =
  "https://us-autocomplete-pro.api.smartystreets.com/lookup?key=117559863585036761&license=us-autocomplete-pro-cloud&search=";
export const LOCALHOST_SMARTY_STREET_ADDRESS_URL =
  "https://us-autocomplete-pro.api.smartystreets.com/lookup?auth-id=075fdb94-1a3c-8165-b16f-d8428e11428e&auth-token=93d2qjdBKGgCLF3klTlV&search=";
export const VALIDATE_ADDRESSS_GET_LAT_LOG =
  "https://us-street.api.smartystreets.com";
export const FB_FIELDS = "id,email,name,first_name,middle_name,last_name";
export const ACCOUNT_NUMBER = "A00004763";
export const INVOICE_NUMBER = "NV00000849";
export const ACCOUNT_KEY = "8ac698a881cda5d60181d045bc4753d9";
export const ACCOUNT_MANAGE_ID = "77611723";

export const API_KEY = "bltae235e99a6dd6f85";
export const API_KEY_PROD = "blt63e9a2c6573b53ee";
export const ACCESS_TOKEN = "cs3417308ce78752a550fd6143";
export const ACCESS_TOKEN_PROD = "cs9d381ae779fc9019169c749c";
export const CHANNEL_ID = "mobile-acm";

export const PAYMENT_IFO_ID = "79847062";
const IAM_GET_USER_DETAILS = `?$select=userPrincipalName&$filter=mail%20eq%20'EMAIL' AND ${
  isProd() || isUAT()
    ? "extension_42054e1351c040b08b185b58c192f49e_pwResetEmailId"
    : "extension_a5021af605b141d78e63d3338ade2884_pwResetEmailId"
} eq 'ACCID'`;

function isProd() {
  return (
    process.env.REACT_APP_ENV === "production" ||
    process.env.NODE_ENV === ENVIRONMENT.production
  );
}

function isUAT() {
  return (
    process.env.REACT_APP_ENV === "uat" ||
    process.env.NODE_ENV === ENVIRONMENT.uat
  );
}

export const ACCOUNT_CONTENT = {
  page: "account_settings_page",
  key: isProd() ? "blt4be7bded6a4c64fd" : "blt8894c30e6af6cf57",
};
export const HELP_SUPPORT_CONTENT = {
  page: "help_and_support_content",
  key: isProd() ? "blt9b1bbae3acc05448" : "blt3ba11b02507b7615",
};
export const VACATION_PAUSE_SERVICE = {
  page: "vacation_pause_service",
  key: isProd() ? "bltd3e22bf0d010aa7a" : "blt6eb2ad80da6a4ed9",
};
export const NON_PAY_SUSPEND = {
  page: "non_pay_suspend",
  key: isProd() ? "blt529f1450105402bb" : "blt4e104463d48abbfa",
};
export const CHANGE_PLAN = {
  page: "change_my_plan",
  key: isProd() ? "bltd05c82f28c9e43d5" : "blt449b691704b95b0f",
};
export const HOME_CONTENT = {
  page: "account_management_home_page",
  key: isProd() ? "blt4a5200fbf350e9af" : "blt71a0570d2338effd",
};
export const APPLY_ACP_MODAL_CONTENT = {
  page: "account_management_apply_acp_modal",
  key: isProd() ? "bltce4548c7ac94df74" : "bltd944f0cccb9be21b",
};
export const ACP_FORM_CONTENT = {
  page: "account_management_acp_signup",
  key: isProd() ? "blteb3d92aa0ba6bba5" : "blt4b34ec7fb4070f10",
};
export const PAYMENT_CONTENT = {
  page: "payments_page",
  key: isProd() ? "bltdec64e72836e0a31" : "blt6587e18a21a64c3e",
};
export const APPOINTMENT_CONTENT = {
  page: "appointment_page",
  key: isProd() ? "blt1270efbc9ca0b15b" : "blta3a865a924a6849c",
};
export const LOGIN_CONTENT = {
  page: "login_content",
  key: isProd() ? "blt040d63a05165d301" : "blt6c30266b823f44a7",
};
export const LOGIN_HEADER_CONTENT = {
  page: "login_header_content",
  key: isProd() ? "blt924fde0c54ebb041" : "bltc2bc0630347993e8",
};

export const NEW_LOGIN_HEADER_CONTENT = {
  page: "am_login_header",
  key: isProd() ? "blteffc804436ba874d" : "bltad5cb3179f224550",
};

export const FOOTER_CONTENT = {
  page: "footer_account_management",
  key: isProd() ? "blt65c8a8a2a6d0e7a6" : "blt348cc772a200ec13",
};

export const NEW_LOGIN_FOOTER_CONTENT = {
  page: "am_login_footer",
  key: isProd() ? "blt2bcefb8727b78417" : "blt4a8ddb59690d2632",
};

export const FOOTER_HOME_CONTENT = {
  page: "am_home_footer",
  key: isProd() ? "bltda56faaf2b56a237" : "bltf251b96e027182af",
};
export const LAYOUT_CONTENT = {
  page: "layout_page",
  key: isProd() ? "bltede987a3555edef6" : "blt90940f7d449591d0",
};

export const LOGIN_FORGET_PAGE_CONTENT = {
  page: "account_management_login_forget",
  key: isProd() ? "blt7c6f4450343311cc" : "blt7007907f00fcf0da",
};

export const SIGNUP_RESET_PAGE_CONTENT = {
  page: "account_management_signup_reset",
  key: isProd() ? "bltc9de9b7208ef6247" : "blt642778d68fdbe530",
};

export const contentApiHeader = {
  api_key: !isProd() ? API_KEY : API_KEY_PROD,
  access_token: !isProd() ? ACCESS_TOKEN : ACCESS_TOKEN_PROD,
};

export const AMS_Cridentials = {
  staging: {
    amsAuthCredentitals: {
      username: "8yn5qUQyuqv98oqA5K1hgzHmttjiCGRg",
      password: "Cfir5imhfpNEdTsT",
    },
  },
  QA: {
    amsAuthCredentitals: {
      username: "NXP5dlMC6eTYM0wPGq3aJBXIuFPir28o",
      password: "6hf4l9Mw1M4H0tWC",
    },
  },
  uat: {
    amsAuthCredentitals: {
      username: "xaqrETFeDAt1KP4iyMlR5cSG7ZZtqy3e",
      password: "Dq80IzGkPBRX4QFB",
    },
  },
  production: {
    amsAuthCredentitals: {
      username: "xaqrETFeDAt1KP4iyMlR5cSG7ZZtqy3e",
      password: "Dq80IzGkPBRX4QFB",
    },
  },
};

export const watsonSearchEnviromentKeys = {
  base_url:
    "https://api.us-east.discovery.watson.cloud.ibm.com/instances/e19b336c-e6f2-4450-9afc-ed6829c84251",
  environment_id: "333e1bdb-87f4-4e80-b0e5-58b828f8f49b",
  collection_id: "3443f1b4-4bd0-405e-b098-e9ff06552b51",
  query_version: "2023-03-31",
  returnType:
    "extracted_metadata.file_type,extracted_metadata.title,metadata.source.url,text",
  token: "YXBpa2V5OjBSR0J4dTJHRlhCemxwaXd5WDctVjdCWUs4bk9Sa0dZOWpVSjhnNmZsN1Az",
  tokenType: "Basic",
};

export const contentApiParams = (param) => {
  return {
    [param.page]: `entries/${param.key}${
      !isProd() ? "?environment=development" : "?environment=production"
    }`,
  };
};

export const getIAM_UserParamsByEmailAndAccountId = (email, accountId) => {
  return IAM_GET_USER_DETAILS?.replace("EMAIL", email)?.replace(
    "ACCID",
    accountId
  );
};

// in web we are using this object for setting refreshToken
export const webRefreshTokenRedux = {};

export const EC_DW_LOGGING_STEPS = {
  login_attempts: "Login Attempted",
  login_success: "Login Success",
  login_failed: "Login Failed",
  username_notfound: "Username or principalName not found",
  token_unavailable: "get iam token unavailable",
  reschedule_attempt: "Reschedule appointment Attempt",
  submit_reschedule: "Submit Reschedule Appointment",
  list_pay_methods: "List Payment Methods",
  change_password: "Change Password",
  account_info_update: "Account info update",
  update_pay_info: "Update Payment Info",
  set_pass_attempt: "Set Password Attempted",
  set_pass_success: "Set Password Success",
  set_pass_failed: "Set Password Failed",
  forgot_pass_attempt: "Forgot Password Attempted",
  sent_otp: "Send OTP",
  sent_otp_for_sign_up: "send-otp-sign-up",
  sent_otp_for_captcha: "send-otp-captcha",
  sent_otp_for_forget_pass: "send-otp-forget-password",
  validate_otp: "Validate OTP",
  validate_otp_for_sign_up: "Validate-otp-sign-up",
  validate_otp_for_captcha: "Validate-otp-captcha",
  validate_otp_For_forget_pass: "Validate-otp-forget-password",
  forgot_pass_success: "Forgot Password Success",
  forgot_pass_failed: "Forgot Password Failed",
  account_not_enabled: "Account Not Enabled",
  account_enabled: "Account Enabled",
  add_primary_payment: "Add Primary Payment",
  add_payment_method: "Add Payment method",
  deleted_payment_method: "Payment method deleted",
  view_statement: "view-statement-clicked",
  view_pdf: "view-pdf-clicked",
  message_tech: "Message Tech",
  add_to_calander: "Add to Calendar",
  upcomming_appointment: "Upcoming Appointment",
  forgot_username_attempt: "Forgot Username Attempted",
  forgot_username_success: "Forgot Username Success",
  forgot_username_failed: "Forgot Username Failed",
  create_account: "Create Account",
  create_acc_set_pass: "Create Acc Set Password",
  create_account_succcess: "Create Account Success",
  create_account_failed: "Create Account Failed",
  pause_service_date_selected: "pause_service_date_selected",
  pause_service_date_defaulted: "pause_service_date_defaulted",
  resume_service_date_selected: "resume_service_date_selected",
  pause_service_cancel_clicked: "pause_service_cancel_clicked",
  pause_service_goBack_clicked: "pause_service_goBack_clicked",
  pause_service_attempted: "pause_service_attempted",
  pause_service_success: "pause_service_success",
  pause_service_failure: "pause_service_failure",
  pause_service_clicked: "pause_service_clicked",
  pause_service_update_clicked: "pause_service_update_clicked",
  pause_service_date_defaulted: "pause_service_date_defaulted",
  resume_service_date_selected: "resume_service_date_selected",
  change_plan_attempted: "Change Plan Attempted",
  change_plan_falied: "Change Plan Failed",
  change_plan_success: "Change Plan Success",
  change_plan_fiber: "Change Plan Fiber Attempted",
  change_plan_voice_add: "Change Plan Add Voice Attempted",
  change_plan_voice_cancel: "Change Plan cancel Voice Attempted",
  change_plan_fiber_success: "Change Plan Fiber Success",
  change_plan_voice_add_success: "Change Plan Add Voice Success",
  change_plan_voice_cancel_success: "Change Plan cancel Voice Success",
  pay_now_attempted: "Pay Now Attempted",
  pay_now_jeopardy_attempted: "Pay Now Jeopardy Attempted",
  pay_now_success: "Pay Now Success",
  pay_now_failed: "Pay Now Failed",
  sign_in_attempt: "Sign In Attempted",
  sign_up_attempt: "Sign Up Attempted",
  sign_up_success: "Sign Up Success",
  sign_up_failed: "Sign Up Failed",
  uni_login_address_attempt: "unilogin_attempt_address",
  uni_login_phoneNumber_attempt: "unilogin_attempt_phone",
  uni_login_address_success: "unilogin_success_address",
  uni_login_phoneNumber_success: "unilogin_success_phone",
  account_unlocked: "Account Unlocked Success",
  account_locked: "Account Locked Success",
  account_switched_success: "User switched to other account success",
  multi_account_login: "Multi accounts user has logged in",
  referalClicked: "Referral link clicked",
};

export const notificationType = {
  creditCard: "CreditCard",
  buryCable: "BuryCable",
  acpBanner: "ACPBanner",
  paymentExpired: "suspend",
  vacationSuspend: "VacationSuspend",
  roadBore: "RoadBore",
  userSuspended: "Suspend",
  nopaymentmethod: "nopaymentmethod",
};

export const companyNameofQF = [
  "QUANTUM FIBER",
  "QF",
  "QF-II",
  "QUANTUM FIBER II",
];

export const CONST_VALUES = {
  account_lock: "ACCOUNT LOCKED",
  customer_id_key:
    isProd() || isUAT()
      ? "extension_42054e1351c040b08b185b58c192f49e_CustomerID"
      : "extension_a5021af605b141d78e63d3338ade2884_CustomerID",
  account_enabled_message_key: `extension_${
    isProd() || isUAT()
      ? `42054e1351c040b08b185b58c192f49e`
      : `a5021af605b141d78e63d3338ade2884`
  }_AccountEnabled_Message`,
};
