import {
  getNotification,
  updateNotification,
} from "../../../shared/hooks/useFetchNotifications";
import ApiUrls from "../../../shared/utils/ApiUrls";

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 300);
  };
};

export const replaceJSX = (text, find1, replace1, find2, replace2) => {
  const output = text.split(find1).flatMap((item) => [item, replace1]);
  output?.pop();
  if (find2 && replace2 && output[output.length - 1]) {
    const output2 = output[output.length - 1]
      .split(find2)
      .flatMap((item) => [item, replace2]);
    output2?.pop();
    output?.pop();
    return [...output, ...output2];
  }
  return output;
};

export const encryptText = (text, privateKey) => {
  try {
    if (window?.pidCryptUtil && window?.pidCrypt) {
      var key = window?.pidCryptUtil.decodeBase64(privateKey);
      var rsa = new window.pidCrypt.RSA();
      //ASN1 parsing
      var asn = window?.pidCrypt.ASN1.decode(
        window?.pidCryptUtil.toByteArray(key)
      );
      var tree = asn.toHexTree();
      //setting the public key for encryption with retrieved ASN.1 tree
      rsa.setPublicKeyFromASN(tree);
      // Base64 encode and encrypt the string
      var crypted = rsa.encrypt(text);
      return window?.pidCryptUtil.encodeBase64(
        window?.pidCryptUtil.convertFromHex(crypted)
      );
    }
  } catch (error) {
    console.info(error);

    return error;
  }
};

export const onEnterKeyPress = (event, callBackFun) => {
  if (event.key === "Enter") {
    event?.preventDefault();
    if (callBackFun && typeof callBackFun === "function") {
      callBackFun(event);
    }
  }
};

export const toggleOneTrust = () => {
  if (window?.OneTrust) {
    window?.OneTrust?.ToggleInfoDisplay();
  }
};

export const phoneNumberValidation = (e) => {
  if (e.keyCode >= 48 && e.keyCode <= 57) {
    e.target.value = e?.target?.value
      ?.replace(/^(\d{3})(\d{3})(\d+)$/, "($1) $2-$3")
      .substr(0, 13);
  } else {
    if (e.keyCode == 8 || e.keyCode == 46) {
      return false;
    } else {
      e.preventDefault();
      return false;
    }
  }
};

export const creditCardExpiryAutoFormat = (e) => {
  if (!/[0-9]/.test(e.key)) {
    e.preventDefault();
  }
  if (e.target.value.length < 7) {
    e.target.value = e.target.value
      .replace(
        /^([1-9]\/|[2-9])$/g,
        "0$1/" // To handle 3/ > 03/
      )
      .replace(
        /^(0[1-9]{1}|1[0-2]{1})$/g,
        "$1/" // 11 > 11/
      )
      .replace(
        /^([0-1]{1})([3-9]{1})$/g,
        "0$1/$2" // 13 > 01/3
      )
      .replace(
        /^(\d)\/(\d\d)$/g,
        "0$1/$2" // To handle 1/11 > 01/11
      )
      .replace(
        /^(0?[1-9]{1}|1[0-2]{1})([0-9]{2})$/g,
        "$1/$2" // 141 > 01/41
      )
      .replace(
        /^(([0]{1,})\/|[0]{1,})$/g,
        "0" // To handle 0/ > 0 and 00 > 0
      )
      .replace(
        /[^\d\/]|^[\/]{0,}$/g,
        "" // To allow only numbers and /
      )
      .replace(
        /\/\//g,
        "/" // Prevent entering more than 1 /
      );
    return true;
  } else {
    e.preventDefault();
    return false;
  }
};

export const getCaptchaToken = async (action = "login") => {
  return new Promise((resolve, reject) => {
    window?.grecaptcha?.enterprise.ready(async () => {
      try {
        const token = await window?.grecaptcha?.enterprise?.execute(
          process.env.REACT_APP_RECAPTCHA_KEY,
          {
            action: action,
          }
        );
        if (token) {
          resolve(token);
        } else {
          reject(Error("something went wrong while genererate captcha token"));
        }
      } catch (error) {
        return reject(error);
      }
    });
  });
};

export const repairAppointmentSortedData = (e) => {
  return e.reduce((a, b) => (a.date > b.date ? a : b));
};

export const getFormattedDate = (value) => {
  let date = new Date(value);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const setNotificationStatusTime = (value, id, type) => {
  let data = value?.notifications?.filter(
    (item) => item.notificationId === id + type
  );
  return data[0]?.createdDate;
};

export const getServiceAddressFromUser = (user) => {
  return user?.addresses?.find(
    (addr) => addr?.addressType === "ServiceAddress"
  );
};
