import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchPlanDetails } from "../../../../shared/hooks/useFetchServiceDetails";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { ACCOUNT_NUMBER } from "../../../../shared/utils/Constants";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type planDetailsInitialStateType = {
  planDetails: any;
  loading: boolean;
  error: any;
  changePlanUrl: string;
  networkType: any;
};
const initialState: planDetailsInitialStateType = {
  planDetails: null,
  loading: false,
  error: null,
  changePlanUrl: "",
  networkType: null,
};

export const getPlanDetails = createAsyncThunk(
  "plan/details",
  async (payloadData:any) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const bandwidthParam= payloadData?.bandwidth?`?bandwidth=${payloadData?.bandwidth}`:""
      const params = 
        {  "": `${API_URLS?.planDetails_QA_Url}/${payloadData?.ban}${bandwidthParam}`
        };
      const response = await fetchPlanDetails(params, headers);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const planDetailSlice = createSlice({
  name: "plan/Details",
  initialState,
  reducers: {
    clearPlanDetailSlice: (state) => {
      return initialState;
    },
    setChangePlanUrl: (state, action: PayloadAction<string>) => {
      state.changePlanUrl = action.payload;
    },
    setNetworkType: (state, action: PayloadAction<string>) => {
      state.networkType = action.payload;
    },
    planDetailSliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPlanDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPlanDetails.fulfilled, (state, action) => {
      state.planDetails = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPlanDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "plan details",
        action.error?.message,
        action?.type
      );
      state.planDetails = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const {
  clearPlanDetailSlice,
  setChangePlanUrl,
  setNetworkType,
  planDetailSliceSetInitialState,
} = planDetailSlice.actions;
export default planDetailSlice.reducer;
