import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { get_AMS_API_token } from "../../../../shared/hooks/useFetchCompleteAddress";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import { getEnvironment } from "../../../../shared/utils/EnvConfig";

type amsTokenInitialStateType = {
  data: any;
  loading: boolean;
  error: any;
};
const initialState: amsTokenInitialStateType = {
  data: null,
  loading: false,
  error: null,
};

export const getAMSToken = createAsyncThunk("ams/token", async () => {
  try {
    const env = getEnvironment();
    const params = {
      apiUrl: env?.ams_token_url,
    };
    const response = await get_AMS_API_token(params, {});
    return response.data;
  } catch (error) {
    throw error;
  }
});

const amsTokenSlice = createSlice({
  name: "AMS/token",
  initialState,
  reducers: {
    clearAmsTokenSlice: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAMSToken.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAMSToken.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAMSToken.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "get AMS token APi failed",
        action.error?.message,
        action?.type
      );
      state.data = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { clearAmsTokenSlice } = amsTokenSlice.actions;
export default amsTokenSlice.reducer;
