import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchNutritionLabel } from "../../../../shared/hooks/useFetchServiceDetails";
import API_URLS from "../../../../shared/utils/ApiUrls";
import store from "../store";

type nutritionLabelInitialStateType = {
  offerResponse: any;
  loading: boolean;
  error: any;
};
const initialState: nutritionLabelInitialStateType = {
  offerResponse: null,
  loading: false,
  error: null,
};

export const getNutritionLabelContent = createAsyncThunk(
  "product/nutritionLabel",
  async () => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const { sessionId } = reduxStore?.user;
      const { planDetails: servicePlan }: any = reduxStore?.planDetails;
      const wireCenter =
        reduxStore?.addressQualification?.data?.addressQualification?.entity
          ?.wireCenter;
      const mmmCode =
        reduxStore?.addressQualification?.data?.addressQualification?.entity
          ?.marketingProfile?.mmmCode;
      const uploadSpeed =
        reduxStore?.addressQualification?.data?.addressQualification?.entity
          ?.fiberQualificationDetails?.maxUpBandwidth;
      const downloadSpeed =
        reduxStore?.addressQualification?.data?.addressQualification?.entity
          ?.fiberQualificationDetails?.maxDownBandwidth;

      let productRatePlanId = "";
      if (servicePlan?.standardPlans?.length > 0) {
        for (
          let index = 0;
          index < servicePlan?.standardPlans?.length;
          index++
        ) {
          const element = servicePlan?.standardPlans[index];
          if (element?.productName?.toLowerCase()?.includes("internet")) {
            productRatePlanId = element?.productRatePlanId;
          }
        }
      }
      const payload = {
        baseline: {
          transactionId: sessionId || "",
          partnerId: "",
          salesCode: "",
          referenceId: "",
          orderId: "",
          agentId: "",
          channel: "acm",
          siteId: headers?.siteId
            ? ["qf", "qf-ii", "quantum fiber"]?.includes(
                headers?.siteId?.toLowerCase()
              )
              ? "qf"
              : headers?.siteId
            : "",
          wirecenter: wireCenter || "",
        },
        productId: productRatePlanId || "",
        wireCenter: wireCenter || "",
        mmmCode: mmmCode || "",
        uploadSpeed: uploadSpeed || "",
        downloadSpeed: downloadSpeed || "",
      };
      const params = {
        "": [API_URLS?.nutritionlabel],
      };
      const response = await fetchNutritionLabel(params, payload, headers);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const nutritionLabelSlice = createSlice({
  name: "product/nutritionLabel",
  initialState,
  reducers: {
    clearNutritionLabelSlice: (state) => {
      return initialState;
    },
    nutritionLabelSliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getNutritionLabelContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getNutritionLabelContent.fulfilled, (state, action) => {
      state.offerResponse = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getNutritionLabelContent.rejected, (state, action) => {
      state.offerResponse = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default nutritionLabelSlice.reducer;
