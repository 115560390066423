import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import fetchContent from "../../../../shared/hooks/useFetchContent";
import {
  ACCOUNT_CONTENT,
  contentApiHeader,
  contentApiParams,
} from "../../../../shared/utils/Constants";
import { errorConditionGTM } from "../../Utils/googleTags";

type accountSettingContentDetailsInitialStateType = {
  accountSettingPageContent: any;
  loading: boolean;
  error: any;
};
const initialState: accountSettingContentDetailsInitialStateType = {
  accountSettingPageContent: null,
  loading: false,
  error: null,
};

export const getAccountSettingPageContent = createAsyncThunk(
  "get/accountSetting-page/content",
  async () => {
    try {
      const response = await fetchContent(
        contentApiParams(ACCOUNT_CONTENT),
        contentApiHeader
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const accountSettingContentDetailSlice = createSlice({
  name: "accountSetting-page/Details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountSettingPageContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAccountSettingPageContent.fulfilled, (state, action) => {
      state.accountSettingPageContent = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getAccountSettingPageContent.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "Account seeting conetentstack",
        action.error?.message,
        action?.type
      );
      state.accountSettingPageContent = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default accountSettingContentDetailSlice.reducer;
