export const ENVIRONMENT = {
  staging: {
    customAPIUrl: "https://stoplight.io/mocks/brightspeed-apiverse/",
    otherUrl1: "",
    otherUrl2: "",
    liveUrl: "https://digitalservicesdev.brightspeed.com/",
    contentStackUrl: "https://cdn.contentstack.io/v3/content_types",
    brightspeedApi: "https://api-dv.brightspeed.com",
    validateUserIAmAPIUrl: "https://graph.microsoft.com/v1.0/users",
    IAM_login_url:
      "https://BrightspeedMMTestB2C.b2clogin.com/BrightspeedMMTestB2C.onmicrosoft.com/B2C_1A_ROPC_Auth_Brightspeed_ADB2C/oauth2/v2.0/token",
    IAM_invalidate_RefreshTokens: "https://graph.microsoft.com/v1.0/users",
    //paypalAPI_Url: "https://api-3t.sandbox.paypal.com",
    paypalAPI_Url: "https://api-dv.brightspeed.com",
    IAM_graphAPI_token:
      "https://login.microsoftonline.com/BrightspeedMMTestB2C.onmicrosoft.com/oauth2/v2.0/token",
    IAM_graph_API_url: "https://graph.microsoft.com/v1.0",
    sessionManagement: "https://api-dv.brightspeed.com/bass/v2/session",
    encryptionUrl: "https://api-dv.brightspeed.com/digital/v1/accountmgmt/ency",
    neverBounceUrl: "https://api.neverbounce.com/v4/single/",
    ams_base_url: "https://api-dv.brightspeed.com",
    ams_token_url: "https://api-qa.brightspeed.com/digital/v2/dev/amsId",
  },
  QA: {
    customAPIUrl: "https://stoplight.io/mocks/brightspeed-apiverse/",
    otherUrl1: "",
    otherUrl2: "",
    liveUrl: "https://digitalservicesqa1.brightspeed.com/",
    liveUrlUAT: "https://digitalservicesuat.brightspeed.com",
    liveUrlUATNew: "https://api-qa.brightspeed.com",
    contentStackUrl: "https://cdn.contentstack.io/v3/content_types",
    brightspeedApi: "https://api-qa.brightspeed.com",
    //brightspeedApi1:"https://api-dev.brightspeed.com",
    validateUserIAmAPIUrl: "https://graph.microsoft.com/v1.0/users",
    IAM_login_url:
      "https://BrightspeedMMTestB2C.b2clogin.com/BrightspeedMMTestB2C.onmicrosoft.com/B2C_1A_ROPC_Auth_Brightspeed_ADB2C/oauth2/v2.0/token",
    IAM_invalidate_RefreshTokens: "https://graph.microsoft.com/v1.0/users",
    paypalAPI_Url: "https://api-dv.brightspeed.com",
    IAM_graphAPI_token:
      "https://login.microsoftonline.com/BrightspeedMMTestB2C.onmicrosoft.com/oauth2/v2.0/token",
    IAM_graph_API_url: "https://graph.microsoft.com/v1.0",
    encryptionUrl: "https://api-qa.brightspeed.com/digital/v2/accountmgmt/ency",
    sessionManagement: "https://api-qa.brightspeed.com/digital/v2/sessionid",
    neverBounceUrl: "https://api.neverbounce.com/v4/single/",
    ams_base_url: "https://api-qa.brightspeed.com",
    ams_token_url: "https://api-qa.brightspeed.com/digital/v2/amsId",
  },
  uat: {
    customAPIUrl: "https://uat.stoplight.io/mocks/brightspeed-apiverse/",
    otherUrl1: "",
    otherUrl2: "",
    liveUrl: "https://digitalservicesdev.brightspeed.com",
    contentStackUrl: "https://cdn.contentstack.io/v3/content_types",
    brightspeedApi: "https://api-uat.brightspeed.com",
    validateUserIAmAPIUrl: "https://graph.microsoft.com/v1.0/users",
    IAM_login_url:
      "https://BrightspeedMMProdB2C.b2clogin.com/BrightspeedMMProdB2C.onmicrosoft.com/B2C_1A_ROPC_Auth_Brightspeed_ADB2C/oauth2/v2.0/token",
    IAM_invalidate_RefreshTokens: "https://graph.microsoft.com/v1.0/users",
    paypalAPI_Url: "https://api-uat.brightspeed.com",
    IAM_graphAPI_token:
      "https://login.microsoftonline.com/BrightspeedMMTestB2C.onmicrosoft.com/oauth2/v2.0/token",
    IAM_graph_API_url: "https://graph.microsoft.com/v1.0",
    encryptionUrl:
      "https://api-uat.brightspeed.com/digital/v2/accountmgmt/ency",
    sessionManagement: "https://api-uat.brightspeed.com/digital/v2/sessionid",
    neverBounceUrl: "https://api.neverbounce.com/v4/single/",
    ams_base_url: "https://api-pr.brightspeed.com",
    ams_token_url: "https://api.brightspeed.com/digital/v2/amsId",
  },
  production: {
    customAPIUrl: "https://stoplight.io/mocks/brightspeed-apiverse/",
    otherUrl1: "",
    otherUrl2: "",
    liveUrl: "https://digitalservices.brightspeed.com/",
    contentStackUrl: "https://cdn.contentstack.io/v3/content_types",
    brightspeedApi: "https://api.brightspeed.com",
    validateUserIAmAPIUrl: "https://graph.microsoft.com/v1.0/users",
    IAM_login_url:
      "https://BrightspeedMMProdB2C.b2clogin.com/BrightspeedMMProdB2C.onmicrosoft.com/B2C_1A_ROPC_Auth_Brightspeed_ADB2C/oauth2/v2.0/token",
    IAM_invalidate_RefreshTokens: "https://graph.microsoft.com/v1.0/users",
    paypalAPI_Url: "https://api-dev.brightspeed.com",
    IAM_graphAPI_token:
      "https://login.microsoftonline.com/BrightspeedMMTestB2C.onmicrosoft.com/oauth2/v2.0/token",
    IAM_graph_API_url: "https://graph.microsoft.com/v1.0",
    sessionManagement: "https://api.brightspeed.com/digital/v2/sessionid",
    encryptionUrl: "https://api.brightspeed.com/digital/v2/accountmgmt/ency",
    neverBounceUrl: "https://api.neverbounce.com/v4/single/",
    ams_base_url: "https://api-pr.brightspeed.com",
    ams_token_url: "https://api.brightspeed.com/digital/v2/amsId",
  },
};

export const setEnvironment = (env) => {
  // process.env.REACT_APP_ENV will be available only in web app
  if (!process.env.REACT_APP_ENV) {
    process.env.NODE_ENV = env;
  }
};

export const getEnvironment = () => {
  return (
    ENVIRONMENT[process.env.REACT_APP_ENV] ||
    ENVIRONMENT[process.env.NODE_ENV] ||
    ENVIRONMENT.production
  );
};
