import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";

// It's for the fetching the content from the content stack
const useFetchContent = async (params = {}, headers) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.contentStackUrl },
    { headers: headers }
  );
};
export default useFetchContent;
