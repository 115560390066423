import { isInternetConnected, isAppEnv } from "./CommonUtils";
import axios from "axios";
import { logObj, tokenObj } from "../../mobile/app/constants/Constant";
import { webRefreshTokenRedux } from "./Constants";
import { getEnvironment } from "../utils/EnvConfig";

const instanceAxios = axios.create({
  // baseURL: API_URL,
});

instanceAxios.interceptors.request.use(async (config) => {
  // web app will throgh the error for isInternetConnected
  let isConnected = window.navigator.onLine || (await isInternetConnected());
  if (!isConnected) {
    // __Dev__ is not defined or in used shared so it through the Error in Web App so commented the logger as of now
    //logError("No internet");
    return Promise.reject("No internet");
  }
  // config.baseURL = await getBaseURL()
  if (!config.url) {
    return config;
  }

  var currentUrl = "";
  Object.entries(config?.url || {}).forEach(([k, v]) => {
    if (k !== "apiUrl") {
      currentUrl = `${currentUrl}${k}/${v}`;
    }
  });

  // console.log("currentUrl", config?.url?.apiUrl + `-` + currentUrl);
  if (!isAppEnv()) {
    // only for web app
    await refreshWebToken(config);
  }

  return {
    ...config,
    baseURL: config?.url?.apiUrl,
    url: currentUrl,
  };
});
instanceAxios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  async (error) => {
    try {
      if (!!logObj && isAppEnv()) {
        // console.log('Error',JSON.stringify(error))
        let errorMessage = JSON.parse(JSON.stringify(error));

        if (errorMessage?.message?.includes("401")) {
          if (tokenObj?.refreshToken) {
            tokenObj?.refreshToken();
          }
        }
        logObj.log(error);
      }
      return Promise.reject(error);
    } catch (error) {
      // __Dev__ is not defined or in used shared so it through the Error in Web App so commented the logger as of now
      // log API error on crashlytics
      if (!!logObj && isAppEnv()) {
        logObj.log(error);
      }
      return Promise.reject(error);
    } finally {
    }
  }
);

async function refreshWebToken(config) {
  const env = getEnvironment();
  if (
    config?.headers?.Authorization &&
    config?.url?.apiUrl === env?.brightspeedApi
  ) {
    if (webRefreshTokenRedux?.webReduxStore) {
      const reduxStore = webRefreshTokenRedux?.webReduxStore?.getState();
      if (reduxStore?.brightspeedToken?.data?.expires_in) {
        if (new Date() > reduxStore?.brightspeedToken?.data?.expires_in) {
          // now refresh token here
          if (webRefreshTokenRedux?.refreshToken) {
            await webRefreshTokenRedux?.refreshToken();
          }
        }
      }
    }
  }
}

export default instanceAxios;
