import color from "../styles/palette/Color";
import { Screens } from "../constants/Screens";
import { isBeforeDate } from "../../../shared/utils/dateUtils";
export const Tabs = ["Tracking", "Payment", "Quick links"];
export const PaymentTabs = ["View payments", "Payment methods"];
export const AccountTabs = ["Account information", "Plan details"];
export const AppointmentsTabs = ["Upcoming appointments", "Past appointments"];
export const SupportTabs = ["Support topics", "Support tools"];

export const SHIMMER_TIME = 2000;
export const SHIMMER_TIME_PAYMENT = 8000;
export const CALLING_SYSTEM = "BOSSAX";
export const WIRE_CENTER = "MANTNCXA";
export const SERVICE_TYPE = "PROVISIONING";
export const TASK_TYPE = "QFGPONFFANRIN";
export const PRODUCT_TYPE = "Quantum Fiber";
export const CUSTOMER_TYPE = "I";
export const AUTO_CANCEL = "N";

export const REACT_APP_PAYPAl_METHOD = "SetExpressCheckout";
export const REACT_APP_PAYPAl_USER =
  "sb-dfzuo20460310_api1.business.example.com";
export const REACT_APP_PAYPAl_PASS = "JUY93RNR2QHEGFE2";
export const REACT_APP_PAYPAl_SIGNATURE =
  "AfcZZffkhOLgGAktBDWZJdpsk80dAuupNgTdqMaztlwbQAVDMsxHRaey";
export const REACT_APP_PAYPAL_VERSION = "62.0";
export const REACT_APP_PAYPAL_BILLINGTYPE = "MerchantInitiatedBilling";
export const REACT_APP_PAYPAL_PAYMENTREQUEST_0_PAYMENTACTION = "Sale";
export const REACT_APP_PAYPAL_RETURNURL_FOR_LOCAL =
  "http://localhost:3000/payment/paymentMethods";
export const REACT_APP_PAYPAL_RETURN_URL =
  "https://www.brspdqa1.brightspeed.com";
export const REACT_APP_PAYPAL_CANCEL_URL =
  "https://www.brspdqa1.brightspeed.com";
export const topIconsArray = [
  require("../assets/icons/cloud.png"),
  require("../assets/icons/home.png"),
  require("../assets/icons/devices.png"),
];

export const SPEED_TEST_URL =
  "https://brightspeedcom.speedtestcustom.com/?serverId=53406";

const HELP_BASE_URL = "https://www.brightspeed.com/help";
export const BASE_URL = "https://www.brightspeed.com";
export const DELETION_BASE_URL =
  "https://www.brightspeed.com/help/account/how-to-cancel-your-brightspeed-service/";

export const SIGNUP_BASE_URL = "https://myaccountqa1.brightspeed.com/signUp";

export const HELP_SUPPORT_URL = {
  FIBER_CONNECTION_TROUBLESHOOT: `${HELP_BASE_URL}/internet/fiber/fiber-troubleshooting.html`,
  SLOW_INTERNET: `${HELP_BASE_URL}/internet/fiber/all-about-speed.html`,
  INTERNET_NOT_WORKING: `${HELP_BASE_URL}/internet/internet-or-phone-not-working/`,
  TROUBLE_SHOOTING_VIEW_ALL: `${HELP_BASE_URL}/internet/#tabs-b90286fc2c-item-5b0b96c604-tab`,
  BILL_STATEMENT: `${HELP_BASE_URL}/internet/fiber/account/view-statements.html`,
  UPDATE_PAYMENT_METHOD: `${HELP_BASE_URL}/internet/fiber/account/update-payment.html`,
  NEED_HELP_PAY_BILL: `${BASE_URL}/aboutus/community/lifeline/`,
  BILLING_VIEW_ALL: `${HELP_BASE_URL}/internet/fiber/account.html`,
  CHANGE_PASSWORD: `${HELP_BASE_URL}/internet/fiber/account/reset-password.html`,
  IF_MOVING: `${HELP_BASE_URL}/account/get-settled-before-you-move/`,
  MANAGE_APPOINTMENT: `${HELP_BASE_URL}/internet/fiber/account/manage-appointment.html`,
  ACCOUNT_VIEW_ALL: `${HELP_BASE_URL}/internet/fiber/account.html`,
  LEARN_ABOUT_MODEM: `${HELP_BASE_URL}/internet/fiber/equipment/modem-faq.html`,
  USE_OWN_ROUTER: `${HELP_BASE_URL}/internet/fiber/equipment/third-party-router.html`,
  UPGRADE_REPLACE_MODEM: `${HELP_BASE_URL}/internet/modems-and-routers/upgrade-or-replace-your-modem/`,
  EQUIPMENT_VIEW_ALL: `${HELP_BASE_URL}/internet/fiber/equipment.html`,
};

export const tabWithIconsArray = [
  {
    title: "Check your network",
    image: require("../assets/icons/suitcase.png"),
  },
  {
    title: "Restart modem",
    image: require("../assets/icons/refresh.png"),
  },
  {
    title: "My plan",
    image: require("../assets/icons/user.png"),
  },
];

export const MenuItems = {
  accountSettings: "Account settings",
  home: "Home",
  myBills: "My bills",
  appointments: "Appointments",
  paymentMethods: "Payment methods",
  helpAndSupport: "Help & support",
  logout: "Logout",
};

export const CURRENT_PLAN_CHECKLIST = [
  {
    title: "You have internet service ",
    value: "at 500mbps",
  },
  {
    title: "You have ",
    value: "VoIP Basic",
  },
  {
    title: "You have ",
    value: "VAS Example 1",
  },
  {
    title: "You have ",
    value: "VAS Example 2",
  },
];

export const getMenuIcon = (key) => {
  switch (key) {
    case MenuItems.home:
      return require("../assets/icons/home.png");

    case MenuItems.accountSettings:
      return require("../assets/icons/account.png");

    case MenuItems.myBills:
      return require("../assets/icons/view_payment.png");

    case MenuItems.paymentMethods:
      return require("../assets/icons/wallet.png");

    case MenuItems.logout:
      return require("../assets/icons/icon-logout.png");

    case MenuItems.appointments:
      return require("../assets/icons/calendar.png");

    case MenuItems.helpAndSupport:
      return require("../assets/icons/device.png");

    default:
      return require("../assets/icons/qrcode.png");
  }
};

export const validationRegex = {
  cardExpiryDateRegex: /^(0[123456789]|10|11|12)([/])([1-2][0-9][0-9][0-9])$/,
  visaRegEx: /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
  mastercardRegEx: /^(?:5[1-5][0-9]{14})$/,
  amexpRegEx: /^(?:3[47][0-9]{13})$/,
  discovRegEx:
    /^(?:65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10}))$/,
};

export const cardNames = {
  visa: "Visa",
  master: "MasterCard",
  americanExp: "AmericanExpress",
  discover: "Discover",
};

export const getFullAddress = (address) => {
  return `${address?.street}, 
${address?.city}, ${address?.state} ${address?.zip}`;
};

export const getFullName = (accountInformation) => {
  return `${accountInformation?.firstName} ${accountInformation?.lastName}`;
};

export const PaymentOptions = {
  creditCard: {
    label: "Credit / Debit Card",
    value: "creditCard",
  },
  payPal: {
    label: "PayPal",
    value: "payPal",
  },
};

export const enviromentNames = {
  production: "production",
  qa: "QA",
  dev: "dev",
};

export const calendarPermissions = {
  denied: "denied",
  restricted: "restricted",
  authorized: "authorized",
  undetermined: "undetermined",
};

export const STORAGE_KEYS = {
  TOKEN_DATA: "token_data",
  EMAIL: "email",
  ACCOUNT_NUMBER: "account_number",
  ADDRESS_ID: "address_id",
  PRINCIPAL_NAME: "principal_name",
  CLIENT_ID: "client_id",
  IS_EMAIL_REMEBERED: "email_remembered",
  IS_PASSWORD_REMEBERED: "password_remembered",
  PASSWORD: "password",
  GLOBAL_TOKEN: "global_token",
  IS_CHAT_VISIBLE: "is_chat_visible",
  SESSION_ID: "session_id",
  PERFORMANCE_CAT: "Performance_cat",
};

export const GRANT_TYPE = {
  CLIENT_CREDENTIALS: "Client_Credentials",
  PASSWORD: "password",
  REFRESH_TOKEN: "refresh_token",
};

export const RESOURCE = "https://graph.microsoft.com/.default";
export const SCOPE = "https://graph.microsoft.com/.default";

export const replaceValue = (string, value, replaceByValue) => {
  let result = "";
  string.includes(replaceByValue)
    ? (result = string.replace(replaceByValue, value))
    : (result = `${string} ${value}`);
  return result;
};

export let logObj = {
  // need to implement
};

export let tokenObj = {};

export const ZOURA_KEYS = {
  stagging: {
    HOST: "https://myaccountdev1.brightspeed.com",
    SANDBOX: "https://sandbox.na.zuora.com/apps/PublicHostedPageLite.do",
    ZOURAID: "8ac6832a843cabdc01843fe28b52743e",
    SANBOXE_SEC:
      "https://sandbox.na.zuora.com/apps/Hosted/lite2/js/HPM2Security.js",
    SUCCESS_PARAM:
      "https://myaccountdev1.brightspeed.com/payment/paymentMethods?success",
  },
  qa: {
    HOST: "https://myaccountqa1.brightspeed.com",
    SANDBOX: "https://sandbox.na.zuora.com/apps/PublicHostedPageLite.do",
    ZOURAID: "8ac6832a84e167320184e3277d586e66",
    SANBOXE_SEC:
      "https://sandbox.na.zuora.com/apps/Hosted/lite2/js/HPM2Security.js",
    SUCCESS_PARAM:
      "https://myaccountqa1.brightspeed.com/payment/paymentMethods?success",
  },
  production: {
    HOST: "https://myaccount.brightspeed.com",
    SANDBOX: "https://sandbox.na.zuora.com/apps/PublicHostedPageLite.do",
    ZOURAID: "8a3685cf853856ec018583d563e00caf",
    SANBOXE_SEC:
      "https://sandbox.na.zuora.com/apps/Hosted/lite2/js/HPM2Security.js",
    SUCCESS_PARAM:
      "https://myaccount.brightspeed.com/payment/paymentMethods?success",
  },
};

export const convertDigits = (n) => {
  n = String(n);
  if (n.length == 1) n = "0" + n;
  return n;
};

export const step1Status = [
  "ASSIGNED",
  "UNSCHEDULED",
  "ALLOCATED",
  "ACCEPTED",
  "RUNNINGLATE",
  "ASSIGNEDATRISK",
];
export const step2Status = [...step1Status, "ENROUTE"];
export const step3Status = ["ON-SITE", "CANNOTCOMPLETE"];
export const step4Status = ["COMPLETED"];

export const getInstallationSteps = (status, appointmentDate) => {
  let currDate = new Date();
  const upcomingAppointmentDate = new Date(appointmentDate);
  if (
    step1Status.includes(status?.toUpperCase()) &&
    isBeforeDate(appointmentDate)
  ) {
    return 1;
  } else if (step2Status.includes(status?.toUpperCase())) {
    return 2;
  } else if (step3Status.includes(status?.replace(/ /g, "").toUpperCase())) {
    return 3;
  } else if (step4Status.includes(status?.toUpperCase()) || status === "" || status === undefined) {
    return 4;
  } else {
    return 1;
  }
};

export const changeIndexPosition = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
};

export const TROUBLESHOOTING_URL =
  "https://www.brightspeed.com/help/internet/wireless/troubleshooting-wifi/";
export const CONTACT_US_URL = "https://www.brightspeed.com/help/contact/";
export const ACCOUNT_URL = "https://www.brightspeed.com/help/account/";

export const communicationPreference = {
  EMAIL: "email",
  CONTACT_NUMBER: "phone",
};

export const PasswordLevels = [
  {
    label: "Weak",
    labelColor: color.textColorDark,
    activeBarColor: color.primaryColor,
  },
  {
    label: "Average",
    labelColor: color.textColorDark,
    activeBarColor: color.primaryColor,
  },
  {
    label: "Strong",
    labelColor: color.textColorDark,
    activeBarColor: color.primaryColor,
  },
];
export const storeURL = {
  ios: "https://apps.apple.com/in/app/testflight/id899247664",
  android: "market://details?id=com.brightspeed.my",
};

export const isEmptyString = (str) => !str || str.length === 0;

export const isEmptyObject = (obj) => !(obj && Object.keys(obj).length > 0);

export const getScreenName = (key) => {
  switch (key) {
    case MenuItems.accountSettings:
      return Screens.Account;

    case MenuItems.home:
      return Screens.Home;

    case MenuItems.myBills:
      return Screens.Bills;

    case MenuItems.helpAndSupport:
      return Screens.Support;

    case MenuItems.appointments:
      return Screens.Calendar;

    case MenuItems.paymentMethods:
      return Screens.Bills;
  }
};

export const getParams = (key) => {
  switch (key) {
    case MenuItems.myBills:
      return {
        index: 0,
      };

    case MenuItems.paymentMethods:
      return {
        index: 1,
      };
  }
};

export const isEmptyObj = (obj) => {
  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) return false;
  }
  return true;
};

export function checkPasswordValidation(value) {
  const isWhitespace = /^(?=.*\s)/;
  if (isWhitespace.test(value)) {
    return "Password must not contain Whitespaces.";
  }
  const isContainsUppercase = /^(?=.*[A-Z])/;
  if (!isContainsUppercase.test(value)) {
    return "Password must have at least one Uppercase Character.";
  }
  const isContainsLowercase = /^(?=.*[a-z])/;
  if (!isContainsLowercase.test(value)) {
    return "Password must have at least one Lowercase Character.";
  }
  const isContainsNumber = /^(?=.*[0-9])/;
  if (!isContainsNumber.test(value)) {
    return "Password must contain at least one Digit.";
  }
  const isContainsSymbol = /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
  if (!isContainsSymbol.test(value)) {
    return "Password must contain at least one Special Symbol.";
  }
  const isValidLength = /^.{10,16}$/;
  if (!isValidLength.test(value)) {
    return "Password must be 10-16 Characters Long.";
  }
  return false;
}

export const paymentGateWayTypes = {
  creditCard: "Paymentech Orbital Gateway",
  payPal: "Paypal Express Checkout Gateway",
};

export const paymentMethodTypes = {
  card: "CreditCard",
  paypal: "PayPalEC",
};
export const storeURLWifiApp = {
  ios: "https://apps.apple.com/us/app/brightspeed-wifi/id1658477411",
  android:
    "https://play.google.com/store/apps/details?id=com.brightspeed.smarthome",
};

export const OneTrustDomainIdentifier = {
  ios: "61f58750-cb6f-4dd1-b39e-a4ac542b0cee-test",
  android: "33f7f6c4-05fa-4504-91e5-60e9804c207f-test",
};

export const OneTrustStorageLocation = 'cdn.cookielaw.org'
export const OneTrustPerformanceCookies = '2'

/**
     * Firebase rules for event name, Key, Screen Name and trace identifier 
     * @param identifier Name of the trace, no leading or trailing whitespace allowed, 
     * no leading underscore '_' character allowed, max length is 100.
*/

export const getFirebaseKeyIdentifire = (name, limit = 100) => {
  return  name.trim()
          .replace(/_/, "")
          .replace(" ","_")
          .trim()
          .substr(0, limit);
}