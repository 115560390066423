import { useState, useEffect } from "react";
import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";

const useFetchAppointment = (params = {}, header = {}) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    getResponse();
  }, []);
  const getResponse = async () => {
    const env = getEnvironment();
    if (!JSON.stringify(params).includes("null")) {
      ApiService.get(
        { ...params, apiUrl: env.brightspeedApi },
        { headers: header }
      ).then((result) => {
        setData(result.data);
      });
    }
  };
  const reloadAppointment = async () => {
    await getResponse();
  };

  return [data, reloadAppointment];
};

// return promise upcoming appointment live API

export const fetchUpcomingAppointment = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    // {
    //   ...params,
    //   apiUrl:
    //     process?.env?.REACT_APP_ENV === "QA"
    //       ? "https://digitalservicesuat.brightspeed.com"
    //       : env.brightspeedApi,
    // },
    { ...params, apiUrl: env.liveUrlUATNew || env.brightspeedApi },
    { headers: headers }
  );
};

// return promise past appointment live API
export const fetchPastAppointment = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};

// return promise appointment available timeslot details live API
export const fetchAppointmentDetailsTimeslots = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
};

// return promise reschedule appointment live API
export const rescheduleAppointment = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.put({ ...params, apiUrl: env.brightspeedApi }, body, {
    headers: headers,
  });
};

export const submitRescheduledAppointment = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const cancelAppointment = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.delete(
    { ...params, apiUrl: env.liveUrl },
    { data: body },
    { headers: headers }
  );
};

export const getMessageTechnician = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};

export const messageToTechnician = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
};

export const dispatchMessageToTechnician = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.put({ ...params, apiUrl: env.brightspeedApi }, body, {
    headers: headers,
  });
};

export default useFetchAppointment;
