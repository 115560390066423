import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

export const routesWithTitles = [
  { path: '/acpform', title: 'ACP Form' },
  { path: '/account-setting', title: 'Account Settings' },
  { path: '/home', title: 'Dashboard Home' },
  { path: '/payment', title: 'Payments' },
  { path: '/appointment', title: 'Appointment' },
  { path: '/pause-service', title: 'Pause Service' },
  { path: '/help-and-support', title: 'Help and Support' },
  { path: '/update-plan', title: 'Update Plan' },
  { path: '/login', title: 'Login' },
  { path: '/new-forget-username', title: 'Forget Username' },
  { path: '/new-forget-username-sent-reminder', title: 'Forgot Username Reminder' },
  { path: '/login-password', title: 'Login Password' },
  { path: '/new-forget-password', title: 'Forgot Password' },
  { path: '/new-forget-password-send-msg-detail', title: 'Forgot Password Send Message' },
  { path: '/new-forget-password-otp', title: 'Forgot Password OTP' },
  { path: '/signUp-password-otp', title: 'Sign Up OTP' },
  { path: '/new-reset-password', title: 'Reset Password' },
  { path: '/new-reset-password-confirm', title: 'Reset Password Confirmation' },
  { path: '/signUp', title: 'Sign Up' },
  { path: '/signUp-send-msg-detail', title: 'Sign Up Send Message' },
  { path: '/signUp-password', title: 'Sign Up Password' },
  { path: '/signUp-confirm', title: 'Sign Up Confirmation' },
  { path: '/set-password', title: 'Set Password' },
  { path: '/set-password-validate', title: 'Set Password' },
  { path: '/set-password-validate-otp', title: 'Set Password OTP' },
  { path: '/change-plan', title: 'Change Plan' },
  { path: '/shop-exp-agent', title: 'Shop Experience Agent' },
  { path: '/uni-login', title: 'Uni Login' },
  { path: '/uni-login-email', title: 'Uni Login Email' },
  { path: '/uni-login-nocaptcha', title: 'Uni Login No Captcha' },
  { path: '/uni-login-no-match', title: 'Uni Login No Match' },
  { path: '/reschedule', title: 'Reschedule Appointment' },
];

const getPageTitle = (pathname: string) => {
  const route = routesWithTitles.find(r => pathname.startsWith(r.path));
  return route ? route.title : 'Brightspeed';
};

const PageTitleChanger: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const title = getPageTitle(location.pathname);
    document.title = title;
  }, [location.pathname]);

  const pageTitle = getPageTitle(location.pathname);

  return (
    <Helmet>
      <title>{pageTitle}</title>
    </Helmet>
  );
};

export default PageTitleChanger;