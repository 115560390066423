import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";

// const useFetchNotifications = async (params = {}, headers = {}) => {
//   useEffect(() => {
//     getResponse();
//   }, []);
//   const getResponse = async () => {
//     const env = getEnvironment();
//     if (!JSON.stringify(params).includes("null")) {
//       ApiService.get(
//         { ...params, apiUrl: env.liveUrlNext },
//         { headers: header }
//       ).then((result) => {
//         setData(result.data);
//       });
//     }
//   };
//   const reload = async () => {
//     await getResponse();
//   };
//   return [data, reload];
// };

export const getNotification = async (params = {}, body = {}, headers = {}) => {
  console.log("get Notification", params);
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};

export const updateNotification = async (
  params = {},
  body = {},
  headers = {}
) => {
  console.log("hooks-->", params);
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    {
      headers: headers,
    }
  );
};

export const updateNotificationStatusApi = async (
  params = {},
  body = {},
  headers = {}
) => {
  console.log("hooks--> update up", params);
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    {
      headers: headers,
    }
  );
};
