import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchServiceDetailsLatestAPI } from "../../../../shared/hooks/useFetchServiceDetails";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";
import { getServiceAddressFromUser } from "../../Utils/utils";

type serviceDetailsInitialStateType = {
  serviceDetails: any;
  loading: boolean;
  error: any;
};
const initialState: serviceDetailsInitialStateType = {
  serviceDetails: null,
  loading: false,
  error: null,
};

export const getserviceDetails = createAsyncThunk(
  "service/details",
  async (user: any) => {
    const { email, billingAccountNumber } = user;
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const serviceAddLocal = getServiceAddressFromUser(user);

      const response = await fetchServiceDetailsLatestAPI(
        {
          "": `${API_URLS?.getServiceDetails_with_ban}?ban=${billingAccountNumber}&addressId=${serviceAddLocal?.addressId}`,
        },
        headers
      );
      let ataProdIndex = null;
      if (response?.data?.subscriptions[0]?.serviceDetails?.length > 0) {
        response.data.subscriptions[0].serviceDetails =
          response?.data?.subscriptions[0]?.serviceDetails?.map((item: any) => {
            if (item?.serviceName) {
              item.serviceName = item?.serviceName
                ?.toString()
                ?.trim()
                ?.replace("fQF ", "");
            }
            return item;
          });
      }
      const ataProduct = response?.data?.subscriptions[0]?.serviceDetails?.find(
        (item: any, i: number) => {
          if (
            item?.serviceType?.trim()?.toUpperCase() === "DEVICE TYPE" &&
            item?.serviceState?.trim()?.toUpperCase() === "ACTIVE"
          ) {
            ataProdIndex = i;
            return item;
          }
        }
      );
      if (ataProduct?.serviceName && ataProduct?.serviceId) {
        response.data.ataProduct = ataProduct;
        response.data?.subscriptions[0].serviceDetails?.splice(ataProdIndex, 1);
      }
      const pendingService =
        (response?.data?.subscriptions?.length > 0 &&
          response?.data?.subscriptions[0]?.serviceDetails?.find(
            (item: any) => {
              if (
                reduxStore?.user?.data?.currentStatus === "Active" &&
                ["CHANGE PENDING", "INSTALLATION PENDING"]?.includes(
                  item?.serviceState?.toUpperCase()
                )
              ) {
                return item;
              }
            }
          )) ||
        null;

      if (pendingService?.serviceId) {
        localStorage.removeItem("changePending");
        response.data.serviceIsInPending = true;
      } else if (
        !pendingService?.serviceId &&
        localStorage.getItem("changePending") === email
      ) {
        response.data.serviceIsInPending = true;
      }
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const serviceDetailSlice = createSlice({
  name: "service/details",
  initialState,
  reducers: {
    clearServiceDetailSlice: (state) => {
      return initialState;
    },
    serviceDetailSliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getserviceDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getserviceDetails.fulfilled, (state, action) => {
      state.serviceDetails = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getserviceDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "service details",
        action.error?.message,
        action?.type
      );
      state.serviceDetails = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { clearServiceDetailSlice, serviceDetailSliceSetInitialState } =
  serviceDetailSlice.actions;
export default serviceDetailSlice.reducer;
