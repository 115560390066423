
import {applyMiddleware, combineReducers, createStore} from "redux"
import CountReducer from "../store/CountReducer";
import DashboardReducer from "../store/DashboardReducer";

import thunk from "redux-thunk";
import TokenReducer from "../store/TokenReducer";
import AddressReducer from "../store/AddressReducer";
import SessionReducer from "../store/SessionReducer";


const configureStore = () => {
    const rootReducer = combineReducers({counter:CountReducer,dashboard:DashboardReducer, tokenData:TokenReducer, address: AddressReducer, sessionid:SessionReducer })
    return createStore(rootReducer,applyMiddleware(thunk))
}

export const COUNTER_ACTION = "counter/fetch"
export const SAVE_PAYMENT_DATA="savePaymentData"
export const GETTOKEN_ACTION = "getTokenData"
export const ADDRESS_ID_ACTION = "getAddressId"
export const ACTIVE_MENU = "getActiveMenu"
export const SESSION_ID_ACTION = "getSessionId"

export default {configureStore};