import moment from "moment";

export const getMonthFromDate = (date) => {
  return moment.utc(date).format("MMM");
};

export const getFullNameOfMonth = (date) => {
  return moment.utc(date).format("MMMM");
};

export const getMonth = (date) => {
  return moment.utc(date).format("MM");
};

export const getDateFromDate = (date) => {
  return moment.utc(date).format("DD");
};

export const getYearFromDate = (date) => {
  return moment.utc(date).format("YYYY");
};

export const getYear = (date) => {
  return moment.utc(date).format("YY");
};

export const getTimeFromDate = (date) => {
  let newDate = date?.replace(/-/g, "/");
  // return  new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
  return new Date(newDate).toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
  });
};

export const getAppointmentDateFromat = (date) => {
  return `${getMonthFromDate(date)} ${getDateFromDate(date)}, ${getYearFromDate(
    date
  )}`;
};

export const getPaymentHistoryDateFormat = (date) => {
  return `${getMonthFromDate(date)} ${getYearFromDate(date)}`;
};

export const getAppointmentTime = (fromDate, toDate) => {
  return `between ${getTimeFromDate(fromDate)} and ${getTimeFromDate(toDate)}`;
};

export const getAppointmentTimeAndroid = (fromDate, toDate) => {
  return `between ${moment(fromDate).format("h A")} and ${moment(toDate).format(
    "h A"
  )}`;
};

export const pastAppointmentDateFromat = (date) => {
  // let formattedDate = `${getMonth(date)}/${getDateFromDate(date)}/${getYearFromDate(date)}`;
  // return statementDateFormat(formattedDate)
  return moment(date, "YYYY-MM-DD").format("MM/DD/YYYY");
};

export const appointmentDateFromat = (date) => {
  return `${getYearFromDate(date)}-${getMonth(date)}-${getDateFromDate(date)}`;
};

export const apptFromat = (date) => {
  let formattedDate = `${getMonth(date)}/${getDateFromDate(date)}/${getYear(
    date
  )}`;
  return statementDateFormat(new Date(formattedDate));
};

export const getTechnicianArrivalTime = (date) => {
  return new Date(date).toLocaleString("en-US", {
    hour: "numeric",
    hour12: true,
  });
};

export const getTechnicianArrivalTimeAndroid = (date) => {
  return moment.utc(date).format("h A");
};

export const getDisabledDays = (
  month,
  year,
  availableDatesArray,
  setMarkedDates
) => {
  const date = new Date(year, month, 1);
  const currentMonthDate = [];
  while (date.getMonth() === month) {
    currentMonthDate.push(moment(new Date(date)).format("YYYY-MM-DD"));
    date.setDate(date.getDate() + 1);
  }

  let dateList = currentMonthDate.filter(
    (val) => !availableDatesArray?.includes(apptFromat(val))
  );
  const disabled = { disabled: true, disableTouchEvent: true };
  let dates = {};
  dateList.forEach((day) => {
    // const copy = moment(pivot);
    dates[day] = disabled;
  });
  setMarkedDates(dates);
  return dates;
};

export const getUpcommingAppointmentTime = (fromDate, toDate) => {
  return `${getTimeFromDate(fromDate)} - ${getTimeFromDate(toDate)}`;
};

export const getUpcommingAppointmentTimeAndroid = (fromDate, toDate) => {
  return `${moment(fromDate).format("h A")} - ${moment(toDate).format("h A")}`;
};

export const statementDateFormat = (date) => {
  return moment(date, "MM-DD-YYYY").format("MM/DD/YYYY");
};

export const getCredidtCardExpiryDateFromat = (date) => {
  return `${getFullNameOfMonth(date)}, ${getYearFromDate(date)}`;
};

export const dueDateFormat = (date) => {
  return moment(date).format("MM/DD/YYYY");
};

export const checkIsPastDate = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  // return date && new Date(date) < today;
  return moment(date).isBefore(moment(), "day");
};

export const checkIsUpcommingAppointment = (date) => {
  return moment(moment(date).add(3, "days")).isSameOrAfter(moment(), "day");
};

export const isBeforeDate = (date) => {
  return moment().isBefore(date);
};

export const getISOString = (date) => {
  return moment.utc(date).toISOString();
};

export const isSameOrBeforeDate = (date1, date2) => {
  return moment(date1).isSameOrBefore(date2);
};
export const getMomentFromString = (strDate) => {
  return moment(strDate, "MM/DD/YYYY");
};

export const calendarDateFormat = (date) => {
  return moment(date, "MM/DD/YYYY").format("YYYY-MM-DD");
};

export const timeAgo = (date) => {
  return moment(date).fromNow();
};
export const isAfterDate = (date) => {
  return moment(date).isAfter();
};

export const isSameOrAfterCurrentDate = (date) => {
  return moment(date).isSameOrAfter(moment().format("MM/DD/YYYY"));
};

export const calcWorkingDays = (fromDate, days) => {
  let count = 0;

  while (count < days) {
    fromDate.setDate(fromDate.getDate() + 1);

    if (fromDate.getDay() != 0 && fromDate.getDay() != 6)
      // Skip weekends

      count++;
  }

  return fromDate;
};

export const allDatesFromPauseService = (length, date, format) => {
  return Array.from({ length }, (_, i) =>
    moment(date, format).add(i, "months").format(format)
  );
};

export const showMsgForThreeMonths = (fromDate) => {
  // fromDate = "05-08-2023";
  var dateToCheck = moment(fromDate);
  var nextThirdMonth = moment(dateToCheck).add(3, "months");
  nextThirdMonth = moment(nextThirdMonth).format("MM/DD/YYYY");
  var todaysDate = moment().format("MM/DD/YYYY");
  var res = moment(todaysDate).isAfter(nextThirdMonth);
  //console.log("res-->", res, todaysDate, nextThirdMonth);
  return res;
};

export const isAfterBillingDate = (suspendedStartDate) => {
  var todaysDate = moment().format("MM/DD/YYYY");
  var res = moment(todaysDate).isSameOrAfter(suspendedStartDate);
  return res;
};

export const checkUpdatePauseDates = (
  onChangeEventSuspendEndDate,
  selectedSuspendEndDate
) => {
  let res = moment(onChangeEventSuspendEndDate).isSame(
    moment(selectedSuspendEndDate)
  );
  return res;
};
