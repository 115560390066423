import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { add, getMonth, getYear, format } from "date-fns";
import { reFetchListOfPaymentMethods } from "../../../../shared/hooks/useFetchPaymentMethod";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type paymentMethodsInitialStateType = {
  paymentMethods: any;
  loading: boolean;
  error: any;
  nextCreditCardExpirationDate: string;
  nextCreditCardExpirationAllDetail: any;
  addEditPaymentMethodLoading: boolean;
  isListOfPaymentMethodDWLogCalled: boolean;
};

const initialState: paymentMethodsInitialStateType = {
  paymentMethods: null,
  loading: false,
  error: null,
  nextCreditCardExpirationDate: "",
  nextCreditCardExpirationAllDetail: null,
  addEditPaymentMethodLoading: false,
  isListOfPaymentMethodDWLogCalled: false,
};

export const getlistOfPaymentMethods = createAsyncThunk(
  "paymentMethods/list",
  async (billingAccountNumber: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const response = await reFetchListOfPaymentMethods(
        {
          [API_URLS?.listPaymentMethods_QA_Url]: billingAccountNumber,
        },
        headers
      );
      if (response?.data?.creditcard) {
        const sortedCreditCardResponse = response?.data?.creditcard?.sort(
          (a: any, b: any) =>
            a.id === response?.data?.defaultPaymentMethodId ? -1 : 1
        );
        response.data.creditcard = sortedCreditCardResponse;
      }

      if (
        (!response?.data?.creditcard ||
          response?.data?.creditcard?.length === 0) &&
        (!response?.data?.paypal || response?.data?.paypal?.length === 0)
      ) {
        response.data.disableChangePlanFeature = true;
      }
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const checkAndGetCreditCardExiration = (listOfPaymentMethodRes: any) => {
  const nextMonthDate = add(new Date(), { months: 3 });
  const month = getMonth(nextMonthDate);
  const currentMonthInActualNumber = month + 1;
  const nextMonthsYear = getYear(nextMonthDate);
  const currentYear = getYear(new Date());
  if (currentMonthInActualNumber && nextMonthsYear) {
    const nextMonthCardWillExp = listOfPaymentMethodRes?.creditcard?.find(
      (card: any) => {
        if (
          card?.expirationMonth <= currentMonthInActualNumber &&
          (card?.expirationYear === nextMonthsYear ||
            card?.expirationYear === currentYear)
        ) {
          return card;
        }
      }
    );
    if (nextMonthCardWillExp?.expirationMonth) {
      return {
        creditCardExpirationDate: format(
          new Date(
            `${nextMonthCardWillExp.expirationMonth}-1-${nextMonthCardWillExp.expirationYear}`
          ),
          "MMMM yyyy"
        ),
        nextMonthCreditCardExpDetails: nextMonthCardWillExp,
      };
    }
  }
};

const paymentMethodsSlice = createSlice({
  name: "paymentMethods",
  initialState,
  reducers: {
    hideCreditCardExpNotification: (state) => {
      state.nextCreditCardExpirationDate = "";
      state.nextCreditCardExpirationAllDetail = null;
    },
    changePrimaryPaymentMethod: (state, action: PayloadAction<string>) => {
      state.paymentMethods = {
        ...state.paymentMethods,
        defaultPaymentMethodId: action.payload,
      };
    },
    onAddEditPaymentMethodLoadingStart: (state) => {
      state.addEditPaymentMethodLoading = true;
    },
    onAddEditPaymentMethodLoadingStop: (state) => {
      state.addEditPaymentMethodLoading = false;
    },
    setIsListOfPaymentMethodDWLogCalled: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isListOfPaymentMethodDWLogCalled = action.payload;
    },
    clearPaymentMethodsSlice: (state) => {
      return initialState;
    },
    paymentMethodsSliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getlistOfPaymentMethods.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getlistOfPaymentMethods.fulfilled, (state, action) => {
      const nextExpCardDetails = checkAndGetCreditCardExiration(action.payload);
      state.nextCreditCardExpirationDate =
        nextExpCardDetails?.creditCardExpirationDate || "";
      state.nextCreditCardExpirationAllDetail =
        nextExpCardDetails?.nextMonthCreditCardExpDetails || null;
      state.paymentMethods = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getlistOfPaymentMethods.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "payment methods",
        action.error?.message,
        action?.type
      );
      state.paymentMethods = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const {
  hideCreditCardExpNotification,
  changePrimaryPaymentMethod,
  onAddEditPaymentMethodLoadingStart,
  onAddEditPaymentMethodLoadingStop,
  clearPaymentMethodsSlice,
  setIsListOfPaymentMethodDWLogCalled,
  paymentMethodsSliceSetInitialState,
} = paymentMethodsSlice.actions;
export default paymentMethodsSlice.reducer;
