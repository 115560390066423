import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import fetchContent from "../../../../shared/hooks/useFetchContent";
import {
  LOGIN_FORGET_PAGE_CONTENT,
  contentApiHeader,
  contentApiParams,
} from "../../../../shared/utils/Constants";

type loginForgetPageContentDetailsInitialStateType = {
  loginForgetPageContent: any;
  loading: boolean;
  error: any;
};
const initialState: loginForgetPageContentDetailsInitialStateType = {
  loginForgetPageContent: null,
  loading: false,
  error: null,
};

export const getLoginForgetPageContent = createAsyncThunk(
  "get/loginForget-page/content",
  async () => {
    try {
      const response = await fetchContent(
        contentApiParams(LOGIN_FORGET_PAGE_CONTENT),
        contentApiHeader
      );
      console.log("resp area-->", response?.data);
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const loginForgetPageContentDetailSlice = createSlice({
  name: "loginForget-page/Details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLoginForgetPageContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLoginForgetPageContent.fulfilled, (state, action) => {
      state.loginForgetPageContent = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getLoginForgetPageContent.rejected, (state, action) => {
      state.loginForgetPageContent = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default loginForgetPageContentDetailSlice.reducer;
