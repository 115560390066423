import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchPaymentHistory } from "../../../../shared/hooks/useFetchPaymentHistory";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type paymentHistoryInitialStateType = {
  paymentHistory: any;
  loading: boolean;
  error: any;
};
const initialState: paymentHistoryInitialStateType = {
  paymentHistory: null,
  loading: false,
  error: null,
};

export const getPaymentHistory = createAsyncThunk(
  "payment/history",
  async (billingAccountNumber: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const response = await fetchPaymentHistory(
        {
          [API_URLS?.paymentHistoryUrl_QA_URL]: billingAccountNumber,
        },
        headers
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const paymentHistorySlice = createSlice({
  name: "payment/history",
  initialState,
  reducers: {
    clearPaymentHistorySlice: (state) => {
      return initialState;
    },
    paymentHistorySliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPaymentHistory.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentHistory.fulfilled, (state, action) => {
      state.paymentHistory = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPaymentHistory.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "payment history",
        action.error?.message,
        action?.type
      );
      state.paymentHistory = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { clearPaymentHistorySlice, paymentHistorySliceSetInitialState } =
  paymentHistorySlice.actions;
export default paymentHistorySlice.reducer;
