import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import fetchContent from "../../../../shared/hooks/useFetchContent";
import {
  PAYMENT_CONTENT,
  contentApiHeader,
  contentApiParams,
} from "../../../../shared/utils/Constants";
import { errorConditionGTM } from "../../Utils/googleTags";

type paymentPageContentInitialStateType = {
  paymentPageContent: any;
  loading: boolean;
  error: any;
};
const initialState: paymentPageContentInitialStateType = {
  paymentPageContent: null,
  loading: false,
  error: null,
};

export const getPaymentPageContent = createAsyncThunk(
  "get/payment-page/content",
  async () => {
    try {
      const response = await fetchContent(
        contentApiParams(PAYMENT_CONTENT),
        contentApiHeader
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const paymentContentDetailSlice = createSlice({
  name: "payment-page/Details",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentPageContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPaymentPageContent.fulfilled, (state, action) => {
      state.paymentPageContent = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPaymentPageContent.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "payment contentstack",
        action.error?.message,
        action?.type
      );
      state.paymentPageContent = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default paymentContentDetailSlice.reducer;
