import { SESSION_ID_ACTION } from "../utils/Util"

const initialState = {
    sessionid:null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case SESSION_ID_ACTION:
      return { ...state, 
        sessionid:payload }
  default:
    return state
  }
}
