import { ADDRESS_ID_ACTION } from "../utils/Util"

const initialState = {
  serviceAddress:null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case ADDRESS_ID_ACTION:
      return { ...state, 
        serviceAddress:payload }
  default:
    return state
  }
}
