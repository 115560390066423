import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";

// return promise for getting burried drop info
export const getBurriedDrop = async (params = {}, headers = {}) => {
  const env = getEnvironment();
  return await ApiService.get(
    { ...params, apiUrl: env.brightspeedApi },
    { headers: headers }
  );
};
