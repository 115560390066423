import { combineReducers } from "@reduxjs/toolkit";
import user from "./slices/user";
import paymentMethods from "./slices/paymentMethods";
import paymentHistory from "./slices/paymentHistory";
import nextPaymentDetails from "./slices/nextPaymentDetails";
import serviceDetails from "./slices/serviceDetails";
import invoiceDetails from "./slices/invoiceDetails";
import paymentMethodDetails from "./slices/paymentMethodDetails";
import contentDetails from "./slices/contentDetails";
import upComingAppointment from "./slices/upComingAppointment";
import pastAppointment from "./slices/pastAppointment";
import layoutContentDetails from "./slices/layoutContentDetails";
import planDetails from "./slices/planDetails";
import accountSettingContent from "./slices/accountSettingContent";
import helpSupportContent from "./slices/helpSupportContent";
import loginHeaderContent from "./slices/loginHeaderContent";
import loginFooterContent from "./slices/loginFooterContent";
import homeFooterContent from "./slices/homeFooterContent";
import appointmentContent from "./slices/appointmentContent";
import paymentContent from "./slices/paymentContent";
import homePageContent from "./slices/homePageContent";
import applyAcpModalContent from "./slices/applyAcpModalPageContent";
import brightspeedToken from "./slices/brightspeedToken";
import burriedDrop from "./slices/burriedDrop";
import repairAppointment from "./slices/repairAppointments";
import notificationDetails from "./slices/notificationDetails";
import pastRepairAppointment from "./slices/pastRepairAppointment";
import acpFormContent from "./slices/acpFormPageContent";
import vacationPauseServiceContent from "./slices/vacationPauseServiceContent";
import nonPaySuspendContent from "./slices/nonPaySuspendContent";
import changePlanContent from "./slices/changePlanContent";
import addressQualification from "./slices/addressQualification";
import amsToken from "./slices/amsToken";
import loginForgetPageContent from "./slices/loginForgetPageContent";
import signUpResetPageContent from "./slices/signUpResetPageContent";
import nutritionLabelContent from "./slices/nutritionLabelContent";

const rootReducer = combineReducers({
  loginHeaderContent,
  loginFooterContent,
  homeFooterContent,
  user,
  paymentMethods,
  paymentHistory,
  nextPaymentDetails,
  serviceDetails,
  invoiceDetails,
  paymentMethodDetails,
  contentDetails,
  upComingAppointment,
  pastAppointment,
  layoutContentDetails,
  planDetails,
  accountSettingContent,
  helpSupportContent,
  appointmentContent,
  paymentContent,
  homePageContent,
  brightspeedToken,
  burriedDrop,
  repairAppointment,
  notificationDetails,
  pastRepairAppointment,
  applyAcpModalContent,
  acpFormContent,
  vacationPauseServiceContent,
  nonPaySuspendContent,
  changePlanContent,
  addressQualification,
  amsToken,
  loginForgetPageContent,
  signUpResetPageContent,
  nutritionLabelContent
});

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>;
