import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchPastAppointment } from "../../../../shared/hooks/useFetchAppointment";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { checkIsPastDate } from "../../../../shared/utils/dateUtils";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";
import { getServiceAddressFromUser } from "../../Utils/utils";

type pastAppointmentInitialStateType = {
  pastAppointment: any;
  loading: boolean;
  error: any;
};
const initialState: pastAppointmentInitialStateType = {
  pastAppointment: null,
  loading: false,
  error: null,
};

// export const getPastAppointment = createAsyncThunk(
//   "past/appointment",
//   async (billingAccountNumber: string) => {
//     try {
//       const response = await fetchPastAppointment({
//         [API_URLS?.fetchPastAppointments]: billingAccountNumber,
//       });
//       if (
//         response?.data?.length &&
//         response?.data[0] &&
//         response?.data[0]?.orderDetails?.orderHistoryDetails
//       ) {
//         return response?.data[0]?.orderDetails?.orderHistoryDetails;
//       }
//       return response?.data;
//     } catch (error) {
//       throw error;
//     }
//   }
// );

export const getPastAppointment = createAsyncThunk(
  "past/appointment",
  async (user: any) => {
    const { billingAccountNumber } = user;
    const serviceAddLocal = getServiceAddressFromUser(user);
    const reduxStore = store.getState();
    const headers = reduxStore?.brightspeedToken?.headers;
    try {
      const response = await fetchPastAppointment(
        {
          "": `${API_URLS?.fetchPastAppointments}?ban=${billingAccountNumber}&addressId=${serviceAddLocal?.addressId}`,
        },
        headers
      );

      if (
        response &&
        response?.data &&
        response?.data?.userAppointments.length &&
        response?.data?.userAppointments[0]?.order
      ) {
        return response?.data?.userAppointments[0]?.order?.filter(
          (anOrder: any) =>
            checkIsPastDate(anOrder?.apptDetails?.appointmentDate) ||
            anOrder?.orderStatus === "Canceled" ||
            anOrder.orderStatus === "Completed" ||
            anOrder.orderStatus === "Cannot Complete"
        );
      }

      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

const pastAppointmentSlice = createSlice({
  name: "past/appointment",
  initialState,
  reducers: {
    clearPastAppointmentSlice: (state) => {
      return initialState;
    },
    pastAppointmentSliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPastAppointment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPastAppointment.fulfilled, (state, action) => {
      state.pastAppointment = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getPastAppointment.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "past appointment",
        action.error?.message,
        action?.type
      );
      state.pastAppointment = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const {
  clearPastAppointmentSlice,
  pastAppointmentSliceSetInitialState,
} = pastAppointmentSlice.actions;
export default pastAppointmentSlice.reducer;
