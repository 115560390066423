import { COUNTER_ACTION } from "../utils/Util"

const initialState = {}

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case COUNTER_ACTION:
      return { ...state, 
        resource:payload.data[0] }
    
  default:
    return state
  }
}
