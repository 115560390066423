import { useState, useEffect } from "react";
import ApiService from "../utils/ApiService";
import { getEnvironment } from "../utils/EnvConfig";

const useFetchUpcomingAppointment = (params = {}, header = {}) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    getResponse();
  }, []);
  const getResponse = async () => {
    const env = getEnvironment();
    if (!JSON.stringify(params).includes("null")) {
      ApiService.get(
        { ...params, apiUrl: env.liveUrlUATNew || env.brightspeedApi },
        { headers: header }
      ).then((result) => {
        setData(result.data);
      });
    }
  };
  const reloadAppointment = async () => {
    await getResponse();
  };

  return [data, reloadAppointment];
};

export default useFetchUpcomingAppointment;

// return promise for getting Repair Appointment info
export const getRepairAppointment = async (
  params = {},
  body = {},
  headers = {}
) => {
  const env = getEnvironment();
  return await ApiService.post(
    { ...params, apiUrl: env.brightspeedApi },
    body,
    { headers: headers }
  );
};
