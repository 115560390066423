import { GETTOKEN_ACTION } from "../utils/Util"

const initialState = {
  globalToken:null
}

export default (state = initialState, { type, payload }) => {
  switch (type) {

  case GETTOKEN_ACTION:
      return { ...state, 
        globalToken:payload }
  default:
    return state
  }
}
