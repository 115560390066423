import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { fetchInVoiceDetails } from "../../../../shared/hooks/useFetchPayment";
import API_URLS from "../../../../shared/utils/ApiUrls";
import { ACCOUNT_NUMBER } from "../../../../shared/utils/Constants";
import { errorConditionGTM } from "../../Utils/googleTags";
import store from "../store";

type invoiceDetailsInitialStateType = {
  invoiceDetails: any;
  loading: boolean;
  error: any;
};
const initialState: invoiceDetailsInitialStateType = {
  invoiceDetails: null,
  loading: false,
  error: null,
};

export const getInvoiceDetails = createAsyncThunk(
  "invoice/details",
  async (accountID: string) => {
    try {
      const reduxStore = store.getState();
      const headers = reduxStore?.brightspeedToken?.headers;
      const response = await fetchInVoiceDetails(
        {
          [API_URLS?.invoiceDetails_QA_Url]: `?accountID=${accountID}`,
        },
        headers
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const invoiceDetailSlice = createSlice({
  name: "invoice/Details",
  initialState,
  reducers: {
    invoiceDetailSliceSetInitialState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getInvoiceDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInvoiceDetails.fulfilled, (state, action) => {
      state.invoiceDetails = action.payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getInvoiceDetails.rejected, (state, action) => {
      errorConditionGTM(
        "BRSPD_Fiber_EC_Flow",
        "Invoice details",
        action.error?.message,
        action?.type
      );
      state.invoiceDetails = null;
      state.loading = false;
      state.error = action.error;
    });
  },
});

export const { invoiceDetailSliceSetInitialState } = invoiceDetailSlice.actions;
export default invoiceDetailSlice.reducer;
